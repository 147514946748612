import styled from "styled-components";
import { Link } from "react-router-dom";
import useZustand from "../utill/state";
import {ReactComponent as IntroductionSvg} from "../assets/introductionPageImg/introduction_icon_balloon.svg";
import {ReactComponent as ArtSvg} from "../assets/artPageImg/art_icon.svg";
import {ReactComponent as EduSvg} from "../assets/eduPageImg/edu_icon.svg";
import {ReactComponent as BrandingSvg} from "../assets/brandingPageImg/branding_icon.svg";
import {ReactComponent as StorySvg} from "../assets/storyPageImg/message_icon.svg";

const Container = styled.nav`
  pointer-events: all;
  &>ul{
    padding: 8.8vw 0;
    display: flex;
    list-style: none;
    column-gap: 50px;
    row-gap: 8.8vw;
  }
  @media (max-width: 1024px) {
    z-index: 7;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 143px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: right;
    background-color: rgba(0,0,0,.1);
    animation: renderNav .7s ease-in-out;
    @keyframes renderNav {
      0%{
        transform: translateX(150%);
      }
      100%{
        transform: translateX(0);
      }
    }
    &>ul{
      padding: 0 0;
      height: 100%;
      width: 100%;
      flex-direction: column;
      background-color: white;
    }
    &>ul>a{
      display: block;
    }
  }
  @media (max-width: 730px) {
    top: 19.4vw;
  }
`
const Title = styled.li`
  display: flex;
  column-gap: 3vw;
  white-space: nowrap;
  font-size: 18px;
  color: ${props=>props.className[0]==props.className[2]? "rgb(245, 185, 25)" : "black"};
  font-weight: 400;
  &:active{
    background-color: rgba(245, 185, 25, .5);
    color: white;
  }
  &>svg{
    display: none;
  }
  @media (max-width: 1024px) {
    padding-left: 3vw;
    text-align: left;
    font-size: 50px;
    font-weight: 700;
    align-items: center;
    &>svg{
      display: flex;
      height: 70px;
      width: 70px;
    }
  }
`

function Nav(){
  const {isPage,setIsPage} = useZustand.usePage();

  return(
    <Container>
      <ul>
        <Link to="/introduction" onClick={()=>setIsPage(1)}><Title className={isPage+" "+1}><IntroductionSvg /><span>소개</span></Title></Link>
        <Link to="/art" onClick={()=>setIsPage(2)}><Title className={isPage+" "+2}><ArtSvg /><span>문화예술</span></Title></Link>
        <Link to="/edu" onClick={()=>setIsPage(3)}><Title className={isPage+" "+3}><EduSvg /><span>교육</span></Title></Link>
        <Link to="/branding" onClick={()=>setIsPage(4)}><Title className={isPage+" "+4}><BrandingSvg /><span>브랜딩</span></Title></Link>
        <Link to="/story" onClick={()=>setIsPage(5)}><Title className={isPage+" "+5}><StorySvg /><span>이야기</span></Title></Link>
      </ul>
    </Container>
  )
}
export default Nav;