import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { ReactComponent as ArtIconSvg } from "../../assets/artPageImg/art_icon.svg";
import { ReactComponent as EduIconSvg } from "../../assets/eduPageImg/edu_icon.svg";
import { ReactComponent as BrandingIconSvg } from "../../assets/brandingPageImg/branding_icon.svg";
import { ReactComponent as StoryIconSvg } from "../../assets/storyPageImg/message_icon.svg";
import { ReactComponent as SymbolLogo } from "../../assets/logo/symbol.svg";
import SpeeachBubble from "../../assets/article/speechBubble.png";
import { ReactComponent as MoreSvg } from "../../assets/article/more.svg";
import { useState } from "react";
import Share from "../../components/Share";

const PostContainer = styled.section`
  position: relative;
  flex: 1;
  height: 537px;
  width: 416px;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: white;
  box-shadow: 1px 1px 37px .1px rgba(0,0,0,.1);
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 1024px) {
    height: 760px;
    width: 666px;
  }
  @media (max-width: 730px) {
    height: 100.37vw;
    width: 88.78vw;
  }
`
const PostBox = styled.div`
  flex: 1;
  height: ${537/2}px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &>p{
    height: 100%;
    display: flex;
    align-items: end;
  }
  @media (max-width: 1024px) {
    height: ${760/2}px;
    width: 666px;
    padding: 36px 26px 36px 36px;
  }
  @media (max-width: 730px) {
    height: ${100.37/2}vw;
    width: 88.78vw;
    padding: 6.01vw 5.85vw 4.39vw 5.85vw;
  }
`
const TitleBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
  @media (max-width: 1024px) {
    row-gap: 36px;
  }
  @media (max-width: 730px) {
    row-gap: 3.17vw;
  }
`
const PostTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &>span{
    flex: 1;
    display: flex;
    column-gap: 7px;
    align-items: center;
  }
  &>span>svg{height: 50px; width: 50px;}
  &>span>p{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -.5px;
    /* background-image: url(${SpeeachBubble});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box; */
    background-color: rgb(238,238,238);
  }
  &>svg{
    height: 45px;
    width: 45px;
    text-align: right;
  }
  @media (max-width: 1024px) {
    &>span{
      column-gap: 11px;
    }
    &>span>svg{height: 83px; width: 83px;}
    &>span>p{
      padding: 16px 18px;
      font-size: 27px;
    }
  }
  @media (max-width: 730px) {
    &>span{
      column-gap: 1.95vw;
    }
    &>span>svg{
      height: 12vw;
      width: 12vw;
    }
    &>span>p{
      height: 8.3vw;
      padding: 0 3.17vw;
      font-size: 3.17vw;
    }
    &>svg{
      height: 6.1vw;
      width: 6.1vw;
    }
  }
`
const PostTitle = styled.h2`
  font-size: 31px;
  font-weight: 600;
  overflow: hidden;
  white-space: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 1024px) {
    font-size: 50px;
    letter-spacing: -.2px;
  }
  @media (max-width: 730px) {
    width: 74vw;
    font-size: 6.34vw;
    font-weight: 700;
  }
`
const TagContainer = styled.div`

`
const TagBox = styled.ul`
  display: flex;
  column-gap: 14px;
  list-style: none;
  overflow: hidden;
`
const Tag = styled.li`
  color: #6a6a6a;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .2px;
  white-space: nowrap;
  @media (max-width: 1024px) {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -.2px;
  }
  @media (max-width: 730px) {
    font-size: 3.17vw;
    font-weight: 300;
  }
`
const ImgBox = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 18px 18px;
  background-color: #F5B919;
  &>img,svg{
    height: ${537/2}px;
    width: 100%;
    border-radius: 0 0 18px 18px;
    fill: #F5B919;
  }
  @media (max-width: 1024px) {
    height: ${760/2}px;
    width: 666px;
    &>img,svg{
      height: ${760/2}px;
      width: 666px;
    }
  }
  @media (max-width: 730px) {
    height: ${100.37/2}vw;
    width: 88.78vw;
    &>img,svg{
      height: ${100.37/2}vw;
      width: 88.78vw;
    }
  }
`

function Article({category, post}){
  const [openShare,setOpenShare] = useState(false);
  const [isArticleValue,setIsArticleValue] = useState();
  return(
    <Link to={"/article/"+post.researchId}>
      <PostContainer>
        <PostBox>
          <TitleBox>
            <PostTop>
              <span>
                {category=="art"
                ? <ArtIconSvg />
                : category=="edu"
                ? <EduIconSvg />
                : category=="story"
                ? <StoryIconSvg />
                : <BrandingIconSvg />
                }
                <p>
                  {post.category=="branding"
                  ? post.subResearchResponseDtos[0].subContent
                  : (category=="art"
                    ? "문화예술공연"
                    : category=="edu"
                    ? "퍼듀바른교육"
                    : category=="story"
                    ? "이야기"
                    : null
                    )
                  }
                </p>
              </span>
              <MoreSvg
                onClick={e=>{
                  e.preventDefault()
                  axios.get(process.env.REACT_APP_API_URL+"research/"+post.researchId)
                  .then(res=>{
                    setIsArticleValue(res.data)
                    setOpenShare(true)
                  })
                  .catch(err=>console.log(err))
                }}
              />
            </PostTop>
            <PostTitle>{post.title}</PostTitle>
          </TitleBox>
          <TagContainer>
            <TagBox>
              {post.subResearchResponseDtos[0].subTitle? post.subResearchResponseDtos[0].subTitle.split("#").slice(1).map(tag=><Tag>#{tag}</Tag>) : <Tag>#더퍼스트에듀</Tag>}
            </TagBox>
          </TagContainer>
        </PostBox>
        <ImgBox className={post.imageUrl}>{post.imageUrl? <img src={post.imageUrl} /> : <SymbolLogo />}</ImgBox>
        {openShare
        ? <Share
            setOpenShare={setOpenShare}
            researchId={post.researchId}
          />
        : null}
      </PostContainer>
    </Link>
  )
}
export default Article;
