import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import PageNation from "../components/PageNation";

import { ReactComponent as IconSvg } from "../assets/storyPageImg/message_icon.svg";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 96px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 40px;
  }
  @media (max-width: 730px) {
    padding: 35px 0 0;
    row-gap: 5.4vw;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>:nth-child(2)>p{
    font-size: 37px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    width: 666px;
    row-gap: 0;
    &>:nth-child(2)>p{
      margin-bottom: 40px;
      font-size: 40px;
      letter-spacing: -.5px;
    }
    &>:nth-child(2)>:first-child{
      display: none;
    }
    &>:nth-child(2)>:last-child{
      display: flex;
    }
    &>:nth-child(3){
      margin-bottom: 95px;
      white-space: pre;
      font-size: 28px;
      letter-spacing: -.5px;
      line-height: 45px;
    }
  }
  @media (max-width: 730px) {
    width: 91vw;
    &>:nth-child(2)>p{
      margin-bottom: 5.4vw;
      font-size: 5.4vw;
    }
    &>:nth-child(3){
      margin-bottom: 13vw;
      font-size: 3.8vw;
      line-height: 5vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  font-size: 47px;
  font-weight: 600;
  letter-spacing: -.5px;
  &>svg{height: 75px; width: 75px;}
  @media (max-width: 1024px) {
    margin-bottom: 17px;
    &>span{
      font-size: 57px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 730px) {
    column-gap: 1.8vw;
    &>svg{height: 10.3vw; width: 10.3vw;}
    &>span{
      font-size: 7.8vw;
    }
  }
`
const ListContainer = styled.article`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  &>a{border-radius: 20px;}
  &>:nth-child(7n+1){background-color: rgb(255, 255, 255);}
  &>:nth-child(7n+2){background-color: rgb(192, 224, 249);}
  &>:nth-child(7n+3){background-color: rgb(254, 207, 93);}
  &>:nth-child(7n+4){background-color: rgb(254, 237, 221);}
  &>:nth-child(7n+5){background-color: rgb(255, 255, 255);}
  &>:nth-child(7n+6){background-color: rgb(192, 224, 249);}
  &>:nth-child(7n+7){background-color: rgb(242, 224, 202);}
  @media (max-width: 1024px) {
    width: 666px;
    gap: 90px;
  }
  @media (max-width: 730px) {
    width: 91vw;
    gap: 12vw;
  }
`
const StoryMedit = styled.section`
  padding: 30px;
  height: 537px;
  width: 416px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  border-radius: 20px;
  box-shadow: 1px 1px 37px .1px rgba(0,0,0,.1);
  &>h4{
    color: #6a6a6a;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -.5px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &>p{
    display: flex;
    display: -webkit-box;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -.5px;
    line-height: 40px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
  }
  @media (max-width: 1024px) {
    padding: 52px 45px;
    height: 910px;
    width: 666px;
    row-gap: 45px;
    &>h4{
      font-size: 25px;
      font-weight: 600;
      letter-spacing: 0;
    }
    &>p{
      font-size: 50px;
      letter-spacing: -.5px;
      line-height: 65px;
    }
  }
  @media (max-width: 730px) {
    padding: 7.1vw 6.1vw;
    row-gap: 6vw;
    height: 124.6vw;
    width: 91vw;
    &>h4{
      font-size: 3.4vw;
    }
    &>p{
      font-size: 6.8vw;
      line-height: 8.7vw;
    }
  }
`
const PageNationContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    margin: 110px 0 130px;
  }
`

function Story() {
  const [isArticleList, setIsArticleList] = useState([])
  const [isPageNation,setIsPageNation] = useState(1);
  useEffect(()=>{
    window.scrollTo(0,0)
    axios.get(process.env.REACT_APP_API_URL+"research/category/story")
    .then(res=>setIsArticleList(res.data))
    .catch(err=>console.log(err))
  },[])

  return (
    <Container>
      <TitleBox>
        <Title><IconSvg /><span>이야기</span></Title>
        <div><p>퍼스트에듀와 공동체와의 톡톡</p></div>
      </TitleBox>
      <ListContainer>
        {isArticleList.slice((isPageNation-1)*9,(isPageNation-1)*9+9).map(article=>(
          <Link to={"/article/"+article.researchId}>
            <StoryMedit>
              <h4>{article.title}</h4>
              <p>{article.subResearchResponseDtos[0].subContent}</p>
            </StoryMedit>
          </Link>
        ))}
      </ListContainer>
      <PageNationContainer>
        <PageNation isArticleList={isArticleList} isPageNation={isPageNation} setIsPageNation={setIsPageNation} />
      </PageNationContainer>
    </Container>
  );
}

export default Story;
