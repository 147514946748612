import styled from "styled-components";
import {ReactComponent as SearchSvg} from "../assets/search.svg";
import Article from "./article/Article";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Container = styled.main`
  width: 1299px;
  padding: 126px 0 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 108px;
  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    padding: 35px 46px 0;
    row-gap: 40px;
  }
  @media (max-width: 730px) {
    width: 100%;
    padding: 12.4vw 3vw 0;
    row-gap: 5.4vw;
  }
`
const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 22px;
  &>:nth-child(2){
    font-size: 37px;
    font-weight: 400;
  }
  &>:nth-child(3){
    margin-bottom: 34px;
    color: #504f4f;
    font-size: 22px;
    font-weight: 450;
    letter-spacing: -.5px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    row-gap: 0;
    &>:nth-child(2){
      margin-bottom: 40px;
      font-size: 33px;
      letter-spacing: -.5px;
    }
    &>:nth-child(3){
      margin-bottom: 95px;
      white-space: pre;
      font-size: 28px;
      letter-spacing: -.5px;
      line-height: 45px;
    }
  }
  @media (max-width: 730px) {
    &>:nth-child(2){
      margin-bottom: 5.4vw;
      font-size: 5vw;
    }
    &>:nth-child(3){
      margin-bottom: 13vw;
      font-size: 3.8vw;
      line-height: 5vw;
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 13px;
  font-size: 47px;
  font-weight: 600;
  letter-spacing: -.5px;
  &>svg{height: 75px; width: 75px;}
  @media (max-width: 1024px) {
    margin-bottom: 17px;
    &>h1{
      font-size: 57px;
      letter-spacing: 0px;
    }
  }
  @media (max-width: 730px) {
    column-gap: 1.8vw;
    &>svg{height: 10.3vw; width: 10.3vw;}
    &>h1{
      font-size: 7.8vw;
    }
  }
`
const ListBox = styled.article`
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    gap: 90px;
  }
  @media (max-width: 730px) {
    row-gap: 12vw;
  }
`
const NoResult = styled.div`
  width: 100%;
  font-size: 33px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 2.7vw;
  }
`

function SearchResult(){
  const [isArticleList, setIsArticleList] = useState([])
  const param = useParams();

  useEffect(()=>{
    axios.get(process.env.REACT_APP_API_URL+"research")
    .then(res=>setIsArticleList(res.data.filter(article=>article.title.includes(param.text)==true)))
    .catch(err=>console.log(err))
  },[param])
  return(
    <Container>
      <TitleBox>
        <Title><SearchSvg /><h1>검색 결과</h1></Title>
        <div>검색하신 "{param.text}" 관련 검색 결과입니다.</div>
        <div>글 제목에 "{param.text}" (이)가 포함된 모든 게시물 입니다.</div>
      </TitleBox>
      <ListBox>
        {isArticleList[0]
        ? isArticleList.map(article=>
        <Article
          post={article}
          category={article.category=="art"
            ? "문화예술공연"
            : article.category=="edu"
            ? "퍼듀바른교육"
            : article.category=="story"
            ? "이야기"
            : "브랜딩"
          }
        />)
        : (param.text? <NoResult>해당 검색어와 연관된 게시물이 없습니다.</NoResult> : <NoResult>검색어를 입력해주시기 바랍니다.</NoResult>)}
      </ListBox>
    </Container>
  )
}
export default SearchResult;